.form
  position: relative
  display: block
  margin: 0
  padding: 0


// label.input
//   input.input-control
//   .input-label
//     span Email

.input
  position: relative
  display: block
  width: 100%
  height: 56px
  margin-bottom: 10px
  padding-left: 115px
  border: 1px solid $light-blue-grey
  border-radius: 5px
  +maxW(769px)
    padding-left: 75px

  &-control
    display: block
    width: 100%
    height: 100%
    padding: 13px 15px
    outline: none
    border: none
    box-shadow: none
    background: none
    font-size: 18px
    line-height: 1.56
    font-weight: 500
    color: $gray-main
    +maxW(799px)
      font-size: 16px

    &::placeholder
      color: $cloudy-blue

    &--gray-bg
      background-color: #edf0f4
      border-radius: 0 4px 4px 0

  &-label
    position: absolute
    top: 0
    left: 0
    width: 115px
    height: 100%
    padding: 14px 15px
    border-right: 1px solid $light-blue-grey
    font-size: 14px
    line-height: 2
    font-weight: 300
    color: $gray-main
    .visible-sm
      display: none

    +maxW(769px)
      width: 75px

  &.input-important
    color: $gray-main
    border: 2px solid $blue-main
    border-radius: 5px

    .input-control
      color: $gray-main

    .input-label
      background-color: $blue-main
      border-right: none
      color: #fff

.form-input
  height: 52px
  padding: 8px 16px
  color: $gray-main
  font-size: 16px
  font-weight: 300
  line-height: 1.75
  background-color: #fff
  border: solid 1px #d4dde5
  border-radius: 5px
  box-shadow: none
  -webkit-appearance: none

  &:focus,
  &:active
    outline: none

  &::placeholder
    color: #bdc6cf

  &.w100
    width: 100%

.loading-dots
  position: relative
  display: none
  height: 100%
  text-align: center

  &__item
    display: inline-block
    width: 15px
    height: 15px
    border-radius: 50%
    background-color: #fff
    animation: sk-bouncedelay 1.4s infinite ease-in-out both

    &--1
      animation-delay: -0.32s

    &--2
      animation-delay: -0.16s

.form-search
  position: relative
  display: flex
  flex-wrap: wrap

  &.loading
    .form-input
      background-color: #dfe7ed
      color: rgba(51, 57, 64, 0.3)

    .form-search__btn
      span,
      i
        display: none

    .loading-dots
      display: flex
      align-items: center
      justify-content: center

  &__input-group
    flex: 1
    position: relative

    .form-input
      width: 100%
      padding-left: 54px
      border-radius: 5px 0 0 5px

  .btn
    height: auto
    border-radius: 0 5px 5px 0

    span
      display: block

      +maxW(840px)
        display: none

    i
      display: none

      +maxW(840px)
        display: block

  &__icon
    position: absolute
    top: 0
    left: 10px
    width: 44px
    height: 100%
    display: flex
    justify-content: center
    align-items: center
    padding-bottom: 3px
    text-align: center

    &.error
      color: #ff3d55

    +maxW(840px)
      left: 0

.form-radio
  position: relative
  flex: 1
  overflow: hidden
  text-align: center

  &__group
    display: flex
    flex-wrap: wrap
    justify-content: space-between
    margin-left: -10px
    margin-right: -10px

    +maxW(575px)
      margin-left: -2px
      margin-right: -2px

  &__input
    position: absolute
    left: -999px
    visibility: hidden
    opacity: 0
    &:checked ~ .form-radio__custom-input
      border: 2px solid #1dacfc

  &__custom-input
    display: flex
    flex-direction: column
    justify-content: center
    height: 53px
    margin-left: 10px
    margin-right: 10px
    border-radius: 10px
    background-color: #ffffff
    border: solid 1px #e6edf4
    transition: border-color 0.2s linear
    &:hover
      cursor: pointer

    +maxW(575px)
      margin-left: 2px
      margin-right: 2px

  &__custom-input-value
    font-size: 14px
    font-weight: 600
    color: $gray-main
    line-height: 18px

  &__custom-input-label
    font-size: 12px
