@import "src/assets/sass/variables"
@import "src/assets/sass/mixins"

.toast-container
  display: flex
  flex-direction: column

.notify
  display: block !important
  max-width: 400px
  margin-left: auto
  margin-right: auto
  margin-bottom: size(2x)
  overflow: hidden
  padding: size(2x) size(3x)
  border: 2px solid transparent
  border-radius: 8px
  background-image: none
  font-size: 14px
  font-weight: 600
  text-align: center
  color: $shark

  &.toast-success
    border-color: $leafy-green
    background-color: rgba($leafy-green, 0.1)

  &.toast-warning
    border-color: $crimson
    background-color: rgba($crimson, 0.1)

.toast-bottom-center
  bottom: 0
