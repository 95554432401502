
$spaceamounts: (0: 0, 1x: 8, 2x: 16, 3x: 24, 4x: 32, 5x: 40, 6x: 48, 7x: 56, 8x: 64, 9x: 72, 10x: 80)
$sides: (top, bottom, left, right)

@each $name, $space in $spaceamounts
  @each $side in $sides
    .m#{str-slice($side, 0, 1)}-#{$name}
      margin-#{$side}: #{$space}px !important

    .p#{str-slice($side, 0, 1)}-#{$name}
      padding-#{$side}: #{$space}px !important

    .m-#{$name}
      margin: #{$space}px !important

    .p-#{$name}
      padding: #{$space}px !important

.ma
  margin: auto

@each $side in $sides
  .m#{str-slice($side, 0, 1)}a
    margin-#{$side}: auto

@each $name,$space in $spaceamounts
  .gap-#{$name}
    gap: #{$space}px !important
