$sides: 16, 24, 40, 56, 80, 120

.inline-svg
  svg
    width: 24px
    height: 24px

  &.red
    svg
      fill: $red

@each $side in $sides
  .inline-svg--#{$side}
    svg
      width: #{$side}px
      height: #{$side}px
