\:root
  --main-font-family: Figtree, sans-serif
  --primary-color: #{$shark}
  --dark-font: #{$dark-shark}
  --light-font: #{$pale-sky}
  --disclamer-font: #{$gray-chateau}
  --main-color: #{$flamingo}
  --shade-color: #{shade($flamingo)}
  --tint-one-color: #{tint1($flamingo)}
  --tint-two-color: #{tint2($flamingo)}
  --tint-three-color: #{tint3($flamingo)}
  --tint-four-color: #{tint4($flamingo)}

  .flamingo-theme
    --main-color: #{$flamingo}
    --shade-color: #{shade($flamingo)}
    --tint-one-color: #{tint1($flamingo)}
    --tint-two-color: #{tint2($flamingo)}
    --tint-three-color: #{tint3($flamingo)}
    --tint-four-color: #{tint4($flamingo, 65%)}

  .sundance-theme
    --main-color: #{$sundance}
    --shade-color: #{shade($sundance)}
    --tint-one-color: #{tint1($sundance)}
    --tint-two-color: #{tint2($sundance)}
    --tint-three-color: #{tint3($sundance)}
    --tint-four-color: #{tint4($sundance, 65%)}

  .alizarin-crimson-theme
    --main-color: #{$alizarin-crimson}
    --shade-color: #{shade($alizarin-crimson)}
    --tint-one-color: #{tint1($alizarin-crimson)}
    --tint-two-color: #{tint2($alizarin-crimson)}
    --tint-three-color: #{tint3($alizarin-crimson)}
    --tint-four-color: #{tint4($alizarin-crimson)}

  .turquoise-theme
    --main-color: #{$turquoise}
    --shade-color: #{shade($turquoise)}
    --tint-one-color: #{tint1($turquoise)}
    --tint-two-color: #{tint2($turquoise)}
    --tint-three-color: #{tint3($turquoise)}
    --tint-four-color: #{tint4($turquoise)}

  .sushi-theme
    --main-color: #{$sushi}
    --shade-color: #{shade($sushi)}
    --tint-one-color: #{tint1($sushi)}
    --tint-two-color: #{tint2($sushi)}
    --tint-three-color: #{tint3($sushi)}
    --tint-four-color: #{tint4($sushi)}

  .dodger-blue-theme
    --main-color: #{$dodger-blue-ribbon}
    --shade-color: #{shade($dodger-blue-ribbon)}
    --tint-one-color: #{tint1($dodger-blue-ribbon)}
    --tint-two-color: #{tint2($dodger-blue-ribbon)}
    --tint-three-color: #{tint3($dodger-blue-ribbon)}
    --tint-four-color: #{tint4($dodger-blue-ribbon)}
  
  .dark-grey-theme
    --main-color: #{$dark-grey}
    --shade-color: #{shade($dark-grey)}
    --tint-one-color: #{tint1($dark-grey)}
    --tint-two-color: #{tint2($dark-grey)}
    --tint-three-color: #{tint3($dark-grey)}
    --tint-four-color: #{tint4($dark-grey)}
