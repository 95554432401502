$font_size: (11, 12, 13, 14, 16, 18, 24, 36, 54)
$font_weight: (normal, 400, 600, 700, bold)
$leadings: 'none' 1, 'tight' 1.2, 'snug' 1.4, 'normal' 1.5, 'relaxed' 1.6
$text_colors: 'shark' $shark, 'pale-sky' $pale-sky, 'gray-chateau' $gray-chateau, 'main' $main-color, 'white' #fff

@each $size in $font_size
  @each $weight in $font_weight
    .f-#{$size}-#{$weight}
      font-size: #{$size}px !important
      font-weight: #{$weight} !important

@each $name, $leading in $leadings
  .lh-#{$name}
    line-height: $leading !important

@each $name, $color in $text_colors
  .color-#{$name}
    color: $color !important

.italic
  font-style: italic !important

.text-overflow
  overflow: hidden
  text-overflow: ellipsis
  white-space: nowrap

.text-center
  text-align: center

.text-left
  text-align: left

.text-right
  text-align: right

.text-link
  text-decoration: underline

  &:hover
    text-decoration: none

.text-uppercase
  text-transform: uppercase
