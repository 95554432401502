html,
body
  width: 100%
  min-height: 100vh

body
  min-width: 375px
  color: $shark
  font-family: 'Figtree', sans-serif
  font-weight: normal
  font-style: normal
  font-stretch: normal
  line-height: normal
  background-color: #fff
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  &.overflow-hidden
    overflow: hidden

button
  padding: 0
  border: none
  box-shadow: none
  background-color: transparent
  &:focus,
  &:active
    outline: none

  &:hover
    cursor: pointer

a
  text-decoration: none
  color: inherit
  &:hover,
  &:active
    text-decoration: none

.d-block
  display: block

.flex
  display: flex

.flex-1
  flex: 1

.flex-column
  display: flex
  flex-direction: column

.flex-center
  display: flex
  justify-content: center

.row
  position: relative
  z-index: 1
  display: flex
  flex-wrap: wrap

.inner-container
  max-width: 1464px
  margin-left: auto
  margin-right: auto
  padding-left: 32px
  padding-right: 32px

.error
  color: #d94e5f

.pointer
  cursor: pointer

.logo-italic--shift
  padding-right: 4px
