@mixin maxW($maxw)
  @media (max-width: $maxw)
    @content

@mixin maxH($maxh)
  @media (max-height: $maxh)
    @content

@mixin minW($minw)
  @media (min-width: $minw)
    @content

@mixin minH($minh)
  @media (min-height: $minh)
    @content

@mixin minWAndmaxW($minw, $maxw)
  @media (min-width: $minw) and (max-width: $maxw)
    @content

@mixin keyframes($animation-name)
  @keyframes #{$animation-name}
    @content

@mixin animation($str)
  animation: #{$str}

@mixin retinaImage($src)
  @media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-devicepixel-ratio: 1.5), only screen and (min-resolution: 1.5dppx)
    background-image: url($src)

@mixin keyframesFrom($animation-name, $steps)
  @keyframes #{$animation-name}
    @for $i from 1 through $steps
      #{$i * (100%/$steps)}
        transform: translate3d(0,-1 * $i * (100%/$steps),0)
