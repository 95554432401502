html
  line-height: 1.15
  -ms-text-size-adjust: 100%
  -webkit-text-size-adjust: 100%

body
  margin: 0

article, aside, footer, header, nav, section
  display: block

h1
  font-size: 2em

h1, h2, h3, h4, h5, h6, p, ul
  margin: 0

figcaption, figure, main
  display: block

figure
  margin: 1em 40px

hr
  box-sizing: content-box
  height: 0
  overflow: visible

pre
  font-family: monospace, monospace
  font-size: 1em

a
  background-color: transparent
  -webkit-text-decoration-skip: objects

abbr[title]
  border-bottom: none
  text-decoration: underline
  text-decoration: underline dotted

b, strong
  font-weight: inherit

b, strong
  font-weight: bolder

code, kbd, samp
  font-family: monospace, monospace
  font-size: 1em

dfn
  font-style: italic

mark
  background-color: #ff0
  color: #000

small
  font-size: 80%

sub, sup
  font-size: 75%
  line-height: 0
  position: relative
  vertical-align: baseline

sub
  bottom: -0.25em

sup
  top: -0.5em

audio, video
  display: inline-block

audio:not([controls])
  display: none
  height: 0

img
  border-style: none

svg:not(:root)
  overflow: hidden

button, input, optgroup, select, textarea
  font-family: 'Figtree', sans-serif
  font-size: 100%
  line-height: 1.15
  margin: 0

button, input
  overflow: visible

button, select
  text-transform: none

button, html [type="button"], [type="reset"], [type="submit"]
  -webkit-appearance: button-bevel

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner
  border-style: none
  padding: 0

button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring
  outline: 1px dotted ButtonText

select
  background-color: transparent

fieldset
  padding: 0.35em 0.75em 0.625em

legend
  box-sizing: border-box
  color: inherit
  display: table
  max-width: 100%
  padding: 0
  white-space: normal

progress
  display: inline-block
  vertical-align: baseline

textarea
  overflow: auto

[type="checkbox"], [type="radio"]
  box-sizing: border-box
  padding: 0

[type="number"]
  &::-webkit-inner-spin-button, &::-webkit-outer-spin-button
    height: auto

[type="search"]
  -webkit-appearance: textfield
  outline-offset: -2px
  &::-webkit-search-cancel-button, &::-webkit-search-decoration
    -webkit-appearance: none

::-webkit-file-upload-button
  -webkit-appearance: button
  font: inherit

details, menu
  display: block

summary
  display: list-item

canvas
  display: inline-block

template, [hidden]
  display: none

html
  box-sizing: border-box

*,
*:before,
*:after
  box-sizing: inherit
