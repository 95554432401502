.form-container
  margin: 0 auto 30px
  width: 100%
  max-width: 350px

.form-divider
  width: 100%
  height: 1px
  background-color: #ced4d9

.control
  position: relative

  &.wide-prepend
    width: 100%

  &__input
    width: 100%
    padding: 19px 8px 19px 16px
    border-radius: 5px
    border: solid 1px $gray-chateau
    font-size: 18px
    line-height: 1.3
    color: $shark
    transition: all 0.2s ease

    &::placeholder
      color: $gray-chateau

    &:focus
      outline: none
      border: solid 1px var(--main-color)
      box-shadow: 0 0 0 1px var(--main-color)

    &.with-icon
      padding-left: 48px

  &__icon
    position: absolute
    top: 20px
    left: 16px
    pointer-events: none

.control-error-message
  padding: size(2x) size(3x)
  border-radius: size(1x)
  border: solid 2px $crimson
  background-color: rgba($crimson, 0.1)
  font-size: 14px
  font-weight: 600
  line-height: 1.2
  color: $shark
