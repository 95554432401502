//colors
$azure: #0babff
$blue-main: #0389ff
$dark-blue: #001540
$pale-grey: #ebeff2
$pale-grey-two: #dadfe6
$slate: #536880
$gray-main: #333940
$red: #ff3d55
$orange: #ff9200
$cloudy-blue-two: #c4d4df
$navy-blue: #001e59
$navy: #001e40
$light-blue-grey: #d5dce6
$cloudy-blue: #b8c2cc
$dark-navy-blue: #001226

$flamingo: #f25a21
$sundance: #c5a95e
$alizarin-crimson: #e72221
$turquoise: #30c6c6
$sushi: #7da747
$dodger-blue: #2681ff
$dark-grey: #2E3338
$dodger-blue-ribbon: #0065f1
$dodger-blue-pattens: #d6e7ff
$dodger-blue-anakiwa: #99c4ff
$dodger-blue-malibu: #70acff
$shark: #1d1d1f
$dark-shark: #0d2033
$pale-sky: #6c7680
$gray-chateau: #a9b0b8
$athens-gray: #e6e9eb
$porcelain: #f4f6f7
$pale-sky-blue: #dae1e6
$navy: #001e40
$strawberry: #ff2638
$green-dark: #22b53a
$crimson: #e01010
$leafy-green: #31A828

$main-color: var(--main-color)

$sizes: (1x: 8px, 2x: 16px, 3x: 24px, 4x: 32px, 5x: 40px, 6x: 48px, 7x: 56px, 8x: 64px, 9x: 72px, 10x: 80px, 11x: 88px, 12x: 96px)
@function size($size)
  @return map-get($sizes, $size)
