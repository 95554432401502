.alert
  position: absolute
  top: 100%
  padding: 11px 20px
  text-align: center
  color: #fff
  font-size: 16px
  font-weight: bold
  line-height: 1.75
  z-index: 10
  transition: all 0.2s

  &:before
    content: ""
    position: absolute
    display: block
    top: -8px
    right: 70px
    width: 16px
    height: 16px
    margin: 0 auto
    transform: rotate(45deg)

  &--search
    display: flex
    flex-direction: column
    left: 0
    right: 0
    max-width: 830px
    margin: size(1x) auto 0
    padding: size(2x) size(3x)
    border: solid 2px $crimson
    border-radius: 8px
    background-color: #fce7e7 !important
    font-size: 14px
    font-weight: 600
    line-height: 1.2
    color: $shark

    &::before
      content: none

  &--form
    left: -1px
    right: -1px
    margin: 15px auto 0
    border-radius: 5px

  &--in-prgress
    background-color: $dark-blue
    &:before
      background-color: $dark-blue

  &--warning
    background-color: $orange
    &:before
      background-color: $orange

  &--danger
    background-color: $red
    &:before
      background-color: $red
