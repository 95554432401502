@import "src/assets/sass/variables"

.radio
  &__input
    position: absolute
    display: block
    width: 0
    height: 0
    visibility: hidden

    &:checked ~ .radio__label
      .radio__label-icon
        border: solid 8px var(--main-color)

      .radio__label-text
        font-weight: 600
        color: $shark

  &__label
    display: flex
    align-items: center
    cursor: pointer

  &__label-icon
    width: 24px
    height: 24px
    border-radius: 26px
    border: solid 2px $gray-chateau
    transition: all 0.2s linear

  &__label-text
    font-size: 18px
    line-height: 1.4
    color: $pale-sky
